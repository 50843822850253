  @font-face {
      font-family: 'SUIT';
      src: url(/fonts/SUIT.ttf) format("truetype");
  }

  @font-face {
      font-family: 'NanumGothic';
      src: url(/fonts/NanumGothic.ttf) format("truetype");
  }

  @font-face {
      font-family: 'NanumGothicBold';
      src: url(/fonts/NanumGothicBold.ttf) format("truetype");
  }

  @font-face {
      font-family: 'NanumGothicExtraBold';
      src: url(/fonts/NanumGothicExtraBold.ttf) format("truetype");
  }

  @font-face {
      font-family: 'NanumGothicLight';
      src: url(/fonts/NanumGothicLight.ttf) format("truetype");
  }